import React, {
    useEffect,
    useState,
} from 'react';
import {
    useNavigate,
    useLocation,
} from 'react-router-dom';
import { useAccount } from 'account';
import { AccountPermissions } from 'account/view_model/account.viewmodel';
import adminPageController from './controller/admin_page.controller';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import Button from 'components/button';
import * as PATHS from 'routing/paths';
import {
    Container,
    Innerr,
} from '../textbooks_page/styles/textbooks_page.style';
import {
    Heading,
    Content,
} from '../textbooks_page/styles/landing_page_textbooks.style';
import {
    PasswordChange,
    GenericPassword,
    GenericSubmitButton,
    PasswordChangeErrorText,
    GenericText,
    GenericNumber,
} from '../profile_page/styles/password_change.style';


export default function AdminHomePage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { account, refreshAccount } = useAccount();

    // Change a user's password - cngusr
    const [cngusradpass, setCngusrAdPass] = useState(''); // Adpass = admin password
    const [cngusrusername, setCngusrUsername] = useState('');
    const [cngusrnewpassword, setCngusrNewPassword] = useState('');
    const [cngusrerrortext, setCngusrErrorText] = useState(''); // Error text for changing a user's password

    // Add a user account - cusr
    const [cusradpass, setCusrAdPass] = useState(''); // Adpass = admin password
    const [cusrname, setCusrName] = useState('');
    const [cusrusername, setCusrUsername] = useState('');
    const [cusrsemleft, setCusrSemleft] = useState(''); // Semleft = semesters left
    const [cusrgradyr, setCusrGradyr] = useState(''); // Gradyr = graduation year
    const [cusrerrortext, setCusrErrorText] = useState(''); // Error text for adding accounts

    // Remove a user account (change to disable as we do not remove accounts) - disacc
    const [disaccadpass, setDisaccAdpass] = useState(''); // Adpass = admin password
    const [disaccusername, setDisaccUsername] = useState('');
    const [disaccerrortext, setDisaccErrorText] = useState(''); // Error text for disabling account

    const onUserPasswordChange = async (event) => {
        event.preventDefault();

        if (cngusradpass.length < 1 || cngusrusername.length < 1 || cngusrnewpassword.length < 1) {
            setCngusrErrorText('Error: All fields must be filled out.');
        }
        else {
            const response = await adminPageController.changeUserPass(cngusradpass, cngusrusername, cngusrnewpassword);
            console.log(response);
            setCngusrAdPass('');
            setCngusrUsername('');
            setCngusrNewPassword('');
            setCngusrErrorText('');
            if (response.data.header.status === 2) {
                setCngusrErrorText('Error: Incorrect admin password or user username.');
            }
        }
    };

    const onAddUserAccount = async (event) => {
        event.preventDefault();

        if (cusradpass.length < 1 || cusrusername.length < 1 || cusrusername.length < 1 || cusrsemleft.length < 1 || cusrgradyr.length < 1) {
            setCusrErrorText('Error: All fields must be filled out.');
        }
        else {
            const response = await adminPageController.createUserAccount(cusradpass, cusrname, cusrusername, cusrsemleft, cusrgradyr);
            console.log(response);
            setCusrAdPass('');
            setCusrName('');
            setCusrUsername('');
            setCusrSemleft('');
            setCusrGradyr('');
            setCusrErrorText('');
            if (response.data.header.status === 2) {
                setCusrErrorText('Error: Incorrect admin password or user username.');
            }
        }
    };

    const onDisableUserAccount = async (event) => {
        event.preventDefault();

        if (disaccadpass.length < 1 || disaccusername.length < 1) {
            setDisaccErrorText('Error: All fields must be filled out.');
        }
        else {
            const response = await adminPageController.disableUserAccount(disaccadpass, disaccusername);
            console.log(response);
            setDisaccAdpass('');
            setDisaccUsername('');
            setDisaccErrorText('');
            if (response.data.header.status === 2) {
                setDisaccErrorText('Error: Incorrect admin password or user username.');
            }
        }
    };

    useEffect(() => {
        if (!account || account === null || [AccountPermissions.INVALID, AccountPermissions.UNAUTH, AccountPermissions.CLASS_SERVICE_REP, AccountPermissions.REGULAR_STUDENT, AccountPermissions.SERVICE_CHAIR].includes(account.permissions) === true) {
            navigate(PATHS.LOGIN, { state: { from: location.pathname }, replace: true });
        }
    }, [account]);

    return (
        <PageTemplate active="admin">
            <Container>
                <Innerr>
                    <Heading>Change User Password</Heading>
                    <PasswordChange onSubmit={onUserPasswordChange}>
                        <GenericPassword
                            value={cngusradpass}
                            onChange={(e) => setCngusrAdPass(e.currentTarget.value)}
                            label="Admin Password"
                            id="adminpassword"
                            name="admin password"
                        />
                        <GenericText
                            value={cngusrusername}
                            onChange={(e) => setCngusrUsername(e.currentTarget.value)}
                            label="Username"
                            id="username"
                            name="username"
                        />
                        <GenericPassword
                            value={cngusrnewpassword}
                            onChange={(e) => setCngusrNewPassword(e.currentTarget.value)}
                            label="New Password"
                            id="newpassword"
                            name="new password"
                        />
                        <GenericSubmitButton>Change Password</GenericSubmitButton>
                        <PasswordChangeErrorText>{cngusrerrortext}</PasswordChangeErrorText>
                    </PasswordChange>
                    <Heading>Create User Account</Heading>
                    <PasswordChange onSubmit={onAddUserAccount}>
                        <GenericPassword
                            value={cusradpass}
                            onChange={(e) => setCusrAdPass(e.currentTarget.value)}
                            label="Admin Password"
                            id="adminpassword"
                            name="admin password"
                        />
                        <GenericText
                            value={cusrname}
                            onChange={(e) => setCusrName(e.currentTarget.value)}
                            label="Name"
                            id="name"
                            name="name"
                        />
                        <GenericText
                            value={cusrusername}
                            onChange={(e) => setCusrUsername(e.currentTarget.value)}
                            label="Username"
                            id="username"
                            name="username"
                        />
                        <GenericNumber
                            value={cusrsemleft}
                            onChange={(e) => setCusrSemleft(e.currentTarget.value)}
                            label="Semesters Left"
                            id="semestersleft"
                            name="semesters left"
                        />
                        <GenericNumber
                            value={cusrgradyr}
                            onChange={(e) => setCusrGradyr(e.currentTarget.value)}
                            label="Graduation Year"
                            id="graduationyear"
                            name="graduation year"
                        />
                        <GenericSubmitButton>Create User</GenericSubmitButton>
                        <PasswordChangeErrorText>{cusrerrortext}</PasswordChangeErrorText>
                    </PasswordChange>
                    <Heading>Disable User Account</Heading>
                    <PasswordChange onSubmit={onDisableUserAccount}>
                        <GenericPassword
                            value={disaccadpass}
                            onChange={(e) => setDisaccAdpass(e.currentTarget.value)}
                            label="Admin Password"
                            id="adminpassword"
                            name="admin password"
                        />
                        <GenericText
                            value={disaccusername}
                            onChange={(e) => setDisaccUsername(e.currentTarget.value)}
                            label="Username"
                            id="username"
                            name="username"
                        />
                        <GenericSubmitButton>Disable Account</GenericSubmitButton>
                        <PasswordChangeErrorText>{disaccerrortext}</PasswordChangeErrorText>
                    </PasswordChange>
                </Innerr>
            </Container>
        </PageTemplate>
    );
}
