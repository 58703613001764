import PropTypes from 'prop-types';
import React, {
  ReactElement,
  useEffect,
} from 'react';
import {
  ContentScreenContainerSx,
  FooterContainerSx,
  HeaderContainerSx,
  PageSx,

} from 'page_template/styles/page_template.style';
import AdminBar from 'admin_bar';
import NavBar from 'nav_bar';
import Footer from 'footer';
import LoadingScreen from 'loading_screen';

/**
 * The page template is used to provide the general format, content and style of
 * a page. The template includes the admin bar, navigation bar, and page footer.
 *
 * The page template also provides the option of showing a loading screen to
 * allow for data to be loaded.
 *
 * @param {Object} props
 * @param {String} props.active the active page.
 * @param {Boolean} props.isLoading whether the page data is loading.
 * @param {ReactElement} props.children the content of the page.
 *
 * @returns {ReactElement} page.
 */
export default function PageTemplate({ active, isLoading, children }) {
  // React router does not automatically scroll to the top upon navigation,
  // so we adjust the window when the page is initially rendered.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageSx>
      {isLoading ?
        <LoadingScreen /> :
        <PageTemplate.ContentScreen active={active} content={children} />}
    </PageSx>
  );
}
PageTemplate.propTypes = {
  active: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
PageTemplate.defaultProps = {
  isLoading: false,
};

PageTemplate.ContentScreen =
  function PageTemplateContentScreen({ active, content }) {
    return (
      <>
        <HeaderContainerSx>
          <AdminBar />
          <NavBar active={active} />
        </HeaderContainerSx>
        <ContentScreenContainerSx>
          {content}
        </ContentScreenContainerSx>
        <FooterContainerSx>
          <Footer />
        </FooterContainerSx>
      </>
    );
  };
PageTemplate.ContentScreen.propTypes = {
  active: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};
