const crypto = globalThis.crypto;

const rsaEncrypt = async (plainText, kty, n, e) => {
    const keyPublic = await crypto.subtle.importKey("jwk", {
        "kty": kty,
        "n": n,
        "e": e,
    }, { name: 'RSA-OAEP', hash: { name: 'SHA-256' }, }, true, []);
    console.log(keyPublic);

    const encryptedData = await crypto.subtle.encrypt(
        { name: "RSA-OAEP", },
        keyPublic,
        Uint8Array.from(Array.from(plainText).map(letter => letter.charCodeAt(0)))
    );
    console.log(encryptedData);

    // const encryptedData = crypto.publicEncrypt(
    //     {
    //         key: keyPublic,
    //         padding: 4,
    //         oaepHash: "sha256",
    //     },
    //     Uint8Array.from(Array.from(plainText).map(letter => letter.charCodeAt(0)))
    // );
    return encryptedData.toString('base64');
}

export default rsaEncrypt;