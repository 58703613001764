import NavBarItemViewModel, {
  navBarItemViewModelFactory,
} from 'nav_bar/view_model/nav_bar_item.viewmodel';
import navBarModel from 'nav_bar/model/nav_bar.model';
import apiAdapter from 'api_adapter';

/**
 * Retrieves the nav bar items.
 *
 * @return {Array<NavBarItemViewModel>} the nav bar items.
 */
const readNavBarItems = () => navBarModel
  .items.map((item) => navBarItemViewModelFactory.create({
    id: item.id,
    label: item.label,
    path: item.path,
  }));

const logout = async () => apiAdapter.logout();

/** Controller for handling nav bar. */
const navBarController = {
  logout,
  readNavBarItems,
};

export default navBarController;
