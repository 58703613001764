import React, {
  ReactElement,
} from 'react';
import {
  ContactLinkSx,
  ContactMailIconSx,
  ContactMenuSx,
  ContactPhoneIconSx,
  ContactTextSx,
  ContainerSx,
  InnerSx,
} from './styles/admin_bar.style';

/**
 * Displays the admin bar. The admin bar contains the admin page navigation link
 * and the contact information for the webmaster.
 *
 * @returns {ReactElement} the admin bar component.
 */
export default function AdminBar() {
  return (
    <ContainerSx>
      <InnerSx>
        <ContactMenuSx>
          <ContactLinkSx href="tel:+13152624745">
            <ContactPhoneIconSx />
            <ContactTextSx>1 (315) 262-4745</ContactTextSx>
          </ContactLinkSx>
          <ContactLinkSx href="mailto:webmaster@clarksonhonors.org">
            <ContactMailIconSx />
            <ContactTextSx>webmaster@clarksonhonors.org</ContactTextSx>
          </ContactLinkSx>
        </ContactMenuSx>
      </InnerSx>
    </ContainerSx>
  );
}
