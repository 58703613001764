import * as PATHS from 'routing/paths';

const navBarModel = {
  items: [
    {
      id: 'home',
      label: 'Home',
      path: PATHS.HOME,
    },
    {
      id: 'about',
      label: 'About',
      path: PATHS.ABOUT,
    },
    {
      id: 'service',
      label: 'Service',
      path: PATHS.SERVICE,
    },
    {
      id: 'professionalexperiences',
      label: 'Pre-Professional',
      path: PATHS.PROFEXP,
    },
    {
      id: 'documents',
      label: 'Documents',
      path: PATHS.DOCUMENTS,
    },
/*    {
      id: 'textbooks',
      label: 'Textbooks',
      path: PATHS.TEXTBOOKS,
    }, */
  ],
};

export default navBarModel;
